<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center mb-5">
        <b-img
          fluid
          src="@/assets/images/celcomLogo/logo.png"
          alt="Logo Celcom"
          style="height: 30px"
        />
      </div>
      <div class="w-100 text-left">
        <div id="card-fondo">
          <h2 class="mb-1">¡Bienvenido a Celcom Checkout!</h2>

          <p class="m-0">
            Se ha creado tu usuario en la plataforma y aquí tienes tu contraseña<br/>
            temporal para acceder.
          </p>
          <p class="mb-2">
            <strong
              >Te recomendamos cambiarla una vez hayas iniciado sesión en la<br/>
              sección <span class="font-italic">Mi Perfil.</span></strong
            >
          </p>

          <div>
            <div class="row" align-v="center">
              <div class="col-2">
                <p v-if="!oculto" class="size-password font-weight-bolder">
                  {{ password }}
                </p>
                <p v-if="oculto" class="size-password font-weight-bolder">
                  ●●●●●●●●
                </p>
              </div>
              <div class="col-10">
                <b-button
                  variant="none"
                  class="p-0 m-0"
                  @click="oculto = !oculto"
                >
                  <feather-icon
                    class="text-primary"
                    :icon="oculto ? 'EyeIcon' : 'EyeOffIcon'"
                    size="16"
                  >
                  </feather-icon>
                </b-button>
              </div>
            </div>
          </div>

          <b-button
            variant="primary"
            class="mt-2 mb-1 btn-sm-block"
            type="submit"
          >
            Ir a Celcom Checkout
          </b-button>

          <hr />
          <div>
            <p class="font-italic">Equipo de Celcom Checkout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import { BForm, BFormInput, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";

export default {
  components: {
    BForm,
    BButton,
    BFormInput,
    BImg,
  },
  data() {
    return {
      password: "Hola123?",
      oculto: true,
    };
  },
  computed: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";

#card-fondo {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-top: 40px;
  margin-top: 40px;
  width: 560px;
}

.size-password {
  font-size: 16px;
  margin-bottom: 0;
}
</style>
